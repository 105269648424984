import "@unocss/reset/tailwind.css";
import { createInertiaApp } from 'inertiax-svelte'
import  { mount } from 'svelte';
import * as Sentry from '@sentry/svelte';
import {DEV} from 'esm-env'
import Layout from "~/layouts/default.svelte"
import "~/global.css";
import axios from "axios";

if (!DEV) Sentry.init({
  environment: window.ENV,
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  dsn: "https://b0d1d2dd3953f2d3aa1ed462d5481d7f@o4506404440637440.ingest.sentry.io/4506404442013696",
})

const csrfToken = document.querySelector("meta[name=csrf-token]").content;
axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  // title: title => title ? `${title} - App` : 'App',

  // Disable progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  // progress: false,

  resolve: async (name) => {
    const pages = import.meta.glob('../pages/**/*.svelte', {
      // eager: true,
    })
    const page = await pages[`../pages/${name}.svelte`]()
    if (!page) {
      console.error(`Missing Inertia page component: '${name}.svelte'`)
    }

    // To use a default layout, import the Layout component
    // and use the following line.
    // see https://inertia-rails.netlify.app/guide/pages#default-layouts
    //
    return { default: page.default, layout: page.layout || Layout }

  },

  setup({ el, App, props }) {
    if (el) {
      mount(App, { target: el, props })
    } else {
      console.error(
        'Missing root element.\n\n' +
          'If you see this error, it probably means you load Inertia.js on non-Inertia pages.\n' +
          'Consider moving <%= vite_javascript_tag "inertia" %> to the Inertia-specific layout instead.',
      )
    }
  },
})
