import { createConsumer } from "@rails/actioncable";
import { writable } from "svelte/store";
import deepmerge from 'deepmerge'
let consumer;
let stores = {}

// target and source are two arrays containing [timestamp, value] tuples.
// returns a new array with both arrays merged and sorted
export function timeseries(target, source) {
  const combined = target.concat(source);
  combined.sort((a, b) => a[0] - b[0]);
  return combined.reduce((acc, curr) => {
    if (acc.length === 0 || acc[acc.length - 1][0] !== curr[0]) {
      acc.push(curr);
    }
    return acc;
  }, []);
}

export function upsert(key) {
  return function(target, source) {
    source.forEach(function(updatedItem) {
      const oldItem = target.find((item) => item[key] == updatedItem[key])
      if (oldItem) Object.assign(oldItem, updatedItem)
      else target.push(updatedItem)
    })
    return target
  }
}

function storeWithHandlers(initial = null) {
  const store = writable(initial);
  const callbacks = {};
  store.on = function(event, callback) {
    callbacks[event] = callback;
    return store;
  };
  store.handle = function(event, data) {
    if (callbacks[event]) {
      callbacks[event](data);
    }
    return store;
  };
  store.on("set", function(data) {
    store.set(data.value);
  });
  store.on("merge", function(data) {
    const arrayMerge = data.key
      ? upsert(data.key)
      : undefined
    store.update(function($data) {
      return deepmerge($data, data.value, {arrayMerge});
    });
  });
  store.reset = function() {
    store.initial ?? store.set(store.initial)
  }
  
  return store;
}

export function getStore(storeId, mergeData, arrayMerge) {
  const store = stores[storeId]

  if (store && mergeData !== undefined) store.update(function($data) {
    const newData = $data ? deepmerge($data, mergeData, { arrayMerge }) : mergeData
    return newData
  })
 
  return (stores[storeId] ||= storeWithHandlers(mergeData));
}

export function initStore(storeId, initial) {
  const store = getStore(storeId, initial)
  store.initial = initial

  return store
}

export function resetStores() {
  Object.values(stores).forEach(store => {
    store.reset()
  });
}

export function perform(storeId, action, ...args) {
  return getStore(storeId).perform(action, ...args);
}

export function unsubscribe(subscription) {
  if (!consumer) return;
  consumer.subscriptions.remove(subscription);
}

export function subscribe(channel, params = {}) {
  if (typeof document == "undefined") return () => {};

  if (!consumer) consumer = createConsumer();

  const subscription = consumer.subscriptions.create(
    { channel, ...params },
    {
      received: function (data) {
        getStore(data.store_id).handle(data.action, data);
      },
    },
  );

  return function unsubscribe() {
    consumer.subscriptions.remove(subscription);
  };
}
